import React from 'react';
import _ from 'lodash';

import {Layout} from '../components/index';
import {htmlToReact, safePrefix} from '../utils';
import { navigate } from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact(props) {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <Layout {...props}>
      <div className="outer">
        <div className="inner-medium">
          <article className="post page post-full">
            <header className="post-header">
              <h1 className="post-title">
                {_.get(props, "pageContext.frontmatter.title")}
              </h1>
            </header>
            {_.get(props, "pageContext.frontmatter.subtitle") && (
              <div className="post-subtitle">
                {htmlToReact(_.get(props, "pageContext.frontmatter.subtitle"))}
              </div>
            )}
            {_.get(props, "pageContext.frontmatter.img_path") && (
              <div className="post-thumbnail">
                <img
                  src={safePrefix(
                    _.get(props, "pageContext.frontmatter.img_path")
                  )}
                  alt={_.get(props, "pageContext.frontmatter.title")}
                />
              </div>
            )}
            <div className="post-content">
              {htmlToReact(_.get(props, "pageContext.html"))}
              <form
                name="contactForm"
                method="post"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                id="contact-form"
                className="contact-form"
              >
                <input type="hidden" name="form-name" value="contactForm" />
                <p hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" />
                  </label>
                </p>
                <p className="form-row">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-input"
                    onChange={handleChange}
                  />
                </p>
                <p className="form-row">
                  <label className="form-label">Email address</label>
                  <input
                    type="email"
                    name="email"
                    className="form-input"
                    onChange={handleChange}
                  />
                </p>
                <p className="form-row">
                  <label className="form-label">Subject</label>
                  <select
                    className="form-input"
                    name="subject"
                    onChange={handleChange}
                  >
                    <option value="Press, Media & Interviews">
                      Press, Media & Interviews
                    </option>
                    <option value="Sponsorships">
                      Podcast & Social Media Sponsorships
                    </option>
                    <option value="Speaking Opportunities">
                      Speaking Opportunities
                    </option>
                    <option value="Coaching & Mentoring">
                      Coaching & Mentoring
                    </option>
                    <option value="Customer Support">Customer Support</option>
                    <option value="Anything Else">Anything Else</option>
                  </select>
                </p>
                <p className="form-row">
                  <label className="form-label">Message</label>
                  <textarea
                    name="message"
                    className="form-textarea"
                    rows="7"
                    onChange={handleChange}
                  />
                </p>
                <input type="hidden" name="form-name" value="contactForm" />
                <p className="form-row form-submit">
                  <button type="submit" className="button">
                    Send Message
                  </button>
                </p>
              </form>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
}
